// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { makeVar, useReactiveVar } from '@apollo/client';

const toasts = makeVar([]);
let key = 0;

export const useToaster = () => ({
    toasts: useReactiveVar(toasts),
    addToast: ({ intent, ...props }) => {
        toasts([
            ...toasts(),
            {
                key: key++,
                intent,
                autoClosable: 'success' === intent ? true : null,
                ...props,
            },
        ]);
    },
    removeToast: ({ key }) => toasts(toasts().filter(toast => toast.key !== key)),
});

// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Link as RouteLink, matchPath } from 'react-router-dom';
import { createLocation, createPath } from 'history';
import routes from '../routes';
import { Tooltip } from '../../jsw';

const urlMapping = {
    '/plesk/admin-panel=server.tools/': '/admin/server/tools',
};

const isMatch = url => {
    const { pathname, search } = createLocation(urlMapping[url] || url);
    if (search.startsWith('?modals')) {
        return true;
    }

    return routes.some(route => matchPath(pathname, route));
};

export const createNavigateHandler = history => e => {
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
        return;
    }

    const url = e.target.closest('a').getAttribute('href');
    if (!isMatch(url)) {
        return;
    }

    e.preventDefault();
    Tooltip.hide();
    history.push(url);
};

const Link = ({ to, ...props }) => {
    if (isMatch(to)) {
        return <RouteLink to={to} {...props} />;
    }

    if (typeof to === 'object' && to !== null) {
        to = createPath(to);
    }

    return <a href={to} {...props} />;
};

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Link.defaultProps = {
    to: null,
};

Link.contextTypes = {
    router: PropTypes.object,
};

export default Link;

// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import PropTypes from 'prop-types';
import { usePageData } from '../../../helpers/pageData';
import PageLayout from './PageLayout';

const PageLayoutWithData = ({ children }) => {
    const data = usePageData();
    return (
        <PageLayout {...data}>
            {children}
        </PageLayout>
    );
};

PageLayoutWithData.propTypes = {
    children: PropTypes.any,
};

PageLayoutWithData.defaultProps = {
    children: undefined,
};

export default PageLayoutWithData;

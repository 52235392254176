// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createLocation } from 'history';
import { useLocation } from 'react-router-dom';
import Link from './Link';

const Brand = ({ baseClassName, className, href, target, img, alt, edition }) => {
    const location = useLocation();
    href = createLocation(href, { reload: true }, null, location);

    return (
        <Link className={classNames(baseClassName, className)} to={href} target={target}>
            <img className={`${baseClassName}__logo`} src={img} alt={alt} />
            {edition ? <span className={`${baseClassName}__edition`}>{edition}</span> : null}
        </Link>
    );
};

Brand.propTypes = {
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    img: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    edition: PropTypes.string,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

Brand.defaultProps = {
    target: undefined,
    edition: undefined,
    className: undefined,
    baseClassName: 'brand',
};

export default Brand;

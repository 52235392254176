// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { ModalDialogBox } from './modal-dialog-box';
import showInternalError from './showInternalError';
import ce from './createElement';
import prepareUrl from './prepareUrl';
import escapeHtml from './escapeHtml';
import Locale from './Locale';

import './message-box.less';

const factory = (config = {}) => {
    if (config.isWebspace) {
        return new WebspaceFoldersMessageBox(config);
    }

    if (config.isAjax) {
        return new MultiCheckboxMessageWindow(config);
    }

    return new MessageBox(config);
};

const TYPE_OK = 'TYPE_OK';
const TYPE_YESNO = 'TYPE_YESNO';
const SUBTYPE_DELETE = 'delete';
const SUBTYPE_TOGGLE = 'toggle';

/**
 * @class messageBox
 * @singleton
 */
export const messageBox = {
    TYPE_OK,
    TYPE_YESNO,
    SUBTYPE_DELETE,
    SUBTYPE_TOGGLE,

    /**
     * Show message box.
     * @param {Object} config
     */
    show(config) {
        const messageBox = factory(config);
        messageBox.show();
        return messageBox;
    },
};

export class MessageBox extends ModalDialogBox {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._cls = 'popup-panel popup-panel-sm popup-panel-centered confirmation-box';
        this._type = this._getConfigParam('type', TYPE_YESNO);
        this._text = this._getConfigParam('text', '');
        this._subtype = this._getConfigParam('subtype', null);
        this._description = this._getConfigParam('description', '');
        this._descriptionWrapperTag = this._getConfigParam('descriptionWrapperTag', 'p');
        this._descriptionDecorator = this._getConfigParam('descriptionDecorator', content => content);
        this._buttonDefault = this._getConfigParam('buttonDefault', this._type === TYPE_YESNO ? 'no' : 'yes');

        const buttonsLocale = Locale.getSection('components.buttons');
        this._buttonTitles = {
            ok: buttonsLocale.lmsg('ok'),
            yes: buttonsLocale.lmsg(this._subtype === SUBTYPE_DELETE ? 'yesRemove' : 'yes'),
            no: buttonsLocale.lmsg('no'),
            wait: buttonsLocale.lmsg('wait'),
            ...this._getConfigParam('buttonTitles'),
        };

        this._onYesClick = this._getConfigParam('onYesClick', null);
        this._onNoClick = this._getConfigParam('onNoClick', null);
        this._onOkClick = this._getConfigParam('onOkClick', null);
        this._needAttention = this._getConfigParam('needAttention', false);
        this._needAttentionText = this._getConfigParam('needAttentionText', '');
        this._needAttentionBlockSubmit = this._getConfigParam('needAttentionBlockSubmit', false);
        this._needAttentionOnChange = this._getConfigParam('needAttentionOnChange', null);

        if (TYPE_YESNO === this._type) {
            if (this._onYesClick) {
                this._onYesClickHandler = this._onYesClick.bind(this);
            } else {
                this._onYesClickHandler = this._defaultOnButtonClick.bind(this);
            }
        }
    }

    _getDescriptionCode() {
        const wrapDescription = description => `<${this._descriptionWrapperTag}>${description}</${this._descriptionWrapperTag}>`;

        if (Array.isArray(this._description)) {
            return this._description.map(wrapDescription).join('');
        }

        return this._descriptionDecorator(wrapDescription(this._description));
    }

    _getNeedAttentionCode() {
        if (!this._needAttention) {
            return '';
        }

        let needAttentionCode = '<hr>';
        if (this._needAttentionBlockSubmit || this._needAttentionOnChange) {
            needAttentionCode += (
                '<p>' +
                    '<span class="b-indent">' +
                        '<span class="b-indent-icon">' +
                            '<input type="checkbox" class="checkbox" id="needAttentionConfirmationCheckbox">' +
                        '</span>' +
                        `<label for="needAttentionConfirmationCheckbox">${this._needAttentionText}</label>` +
                    '</span>' +
                '</p>'
            );
        } else {
            needAttentionCode += `<p class="hint-warning">${this._needAttentionText}</p>`;
        }
        needAttentionCode += '<hr>';

        return needAttentionCode;
    }

    _initComponentElement() {
        super._initComponentElement();

        this._updateComponentElement(
            ce('div.popup-wrapper',
                ce('div.popup-container',
                    ce('div.popup-content',
                        ce('div.popup-content-area',
                            ce(`div.confirmation-msg${this._subtype ? `.mw-${this._subtype}` : ''}`,
                                this._text ? ce('p', this._text) : null,
                                this._getDescriptionCode(),
                                this._getNeedAttentionCode(),
                                ce('div.btns-container',
                                    this._type === TYPE_YESNO ? [
                                        ce(`button.btn${this._subtype === SUBTYPE_DELETE ? '.btn-danger' : this._buttonDefault === 'yes' ? '.action' : ''}`, {
                                            type: 'button',
                                            'data-action': 'yes',
                                        }, this._buttonTitles.yes),
                                        ce(`button.btn${this._subtype === SUBTYPE_DELETE ? '' : this._buttonDefault === 'no' ? '.action' : ''}`, {
                                            type: 'button',
                                            'data-action': 'no',
                                        }, this._buttonTitles.no),
                                    ] : this._type === TYPE_OK ? [
                                        ce('button.btn.action', {
                                            type: 'button',
                                            'data-action': 'yes',
                                        }, this._buttonTitles.ok),
                                    ] : null
                                )
                            )
                        )
                    )
                )
            )
        );

        this._componentElement.style.display = 'none';
    }

    _disableYesButton() {
        if (TYPE_YESNO === this._type) {
            const buttonYes = this._componentElement.querySelector('.btns-container .btn');
            buttonYes.classList.add('disabled');
            buttonYes.disabled = true;
            buttonYes.removeEventListener('click', this._onYesClickHandler);
        }
    }

    _enableYesButton() {
        if (TYPE_YESNO === this._type) {
            const buttonYes = this._componentElement.querySelector('.btns-container .btn');
            buttonYes.classList.remove('disabled');
            buttonYes.disabled = false;
            buttonYes.addEventListener('click', this._onYesClickHandler);
        }
    }

    _addEvents() {
        super._addEvents();

        const buttons = this._componentElement.querySelectorAll('button');

        // add default behavior - hide dialog
        buttons.forEach(button => {
            button.addEventListener('click', this._defaultOnButtonClick.bind(this));
        });

        if (TYPE_YESNO === this._type) {
            if (this._onYesClick) {
                const buttonYes = buttons[0];
                buttonYes.addEventListener('click', this._onYesClickHandler);
            }

            if (this._onNoClick) {
                const buttonNo = buttons[buttons.length - 1];
                buttonNo.addEventListener('click', event => {
                    this._onNoClick(event);
                });
            }

            if (this._needAttention && this._needAttentionBlockSubmit) {
                const inputs = this._componentElement.querySelectorAll('input');
                const confirmationCheckbox = inputs[inputs.length - 1];
                confirmationCheckbox.addEventListener('click', () => {
                    if (confirmationCheckbox.checked) {
                        this._enableYesButton();
                    } else {
                        this._disableYesButton();
                    }
                });
            }

            if (this._needAttention && this._needAttentionOnChange) {
                const inputs = this._componentElement.querySelectorAll('input');
                const confirmationCheckbox = inputs[inputs.length - 1];
                confirmationCheckbox.addEventListener('click', () => {
                    this._needAttentionOnChange(confirmationCheckbox.checked);
                });
            }
        }

        if (TYPE_OK === this._type) {
            if (this._onOkClick) {
                const buttonOk = buttons[0];
                buttonOk.addEventListener('click', event => {
                    this._onOkClick(event);
                });
            }
        }
    }

    _addTooltips() {
        // disable parent implementation
    }

    _defaultOnButtonClick() {
        this.hide();
    }

    show() {
        super.show();
        this._componentElement.querySelector(`[data-action="${this._buttonDefault}"]`)?.focus();
        if (this._needAttention && this._needAttentionBlockSubmit) {
            this._disableYesButton();
        }
    }
}

// TODO PMT-4391: Plesk migrator is broken in Plesk 17.9.1 after cutting core's classes
MessageBox.subclasses = [];

export class AjaxMessageBox extends MessageBox {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._requestUrl = this._getConfigParam('requestUrl', '');
        this._requestMethod = this._getConfigParam('requestMethod', 'post');
        this._requestParams = this._getConfigParam('requestParams', '');
        this._contentContainerId = this._getConfigParam('contentContainerId', `${this._id}contentContainer`);
        this._confirmationCheckboxId = this._getConfigParam('confirmationCheckboxId', `${this._id}confirmationCheckbox`);
        this._loadingTitle = this._getConfigParam('loadingTitle', '');
        this._progress = false;
    }

    _getNeedAttentionCode() {
        return `<div id="${this._contentContainerId}"></div>${super._getNeedAttentionCode()}`;
    }

    _showSpinner() {
        if (TYPE_YESNO === this._type) {
            this._progress = true;
            this._componentElement.querySelector('button').innerHTML = `<span class="wait">${this._buttonTitles.wait}</span>`;
        }
    }

    _hideSpinner() {
        if (TYPE_YESNO === this._type) {
            this._progress = false;
            this._componentElement.querySelector('button').innerHTML = this._buttonTitles.yes;
        }
    }

    show() {
        super.show();
        if (!this._requestUrl || this._progress) {
            return;
        }

        new Ajax.Request(prepareUrl(this._requestUrl), {
            method: this._requestMethod,
            parameters: this._requestParams,
            onCreate: this._onCreate.bind(this),
            onSuccess: this._onSuccess.bind(this),
            onFailure: this._onFailure.bind(this),
        });
    }

    _onCreate() {
        this._disableYesButton();
        this._showSpinner();
    }

    _onSuccess() {
        this._hideSpinner();
        this._enableYesButton();
    }

    _onFailure() {
        this._hideSpinner();
        this._enableYesButton();
    }
}

// TODO PMT-4391: Plesk migrator is broken in Plesk 17.9.1 after cutting core's classes
AjaxMessageBox.superclass = MessageBox;

export class MultiCheckboxMessageWindow extends AjaxMessageBox {
    _onSuccess(transport) {
        this._hideSpinner();

        const result = JSON.parse(transport.responseText);

        const { content } = result;
        let contentCode = '';
        if (Array.isArray(content)) {
            if (content.length > 0) {
                contentCode = '<ul class="ul">';
                content.forEach(item => {
                    contentCode += `<li>${item}</li>`;
                });
                contentCode += '</ul>';
            }
        } else {
            contentCode = content;
        }

        let descriptionCode = '';
        if (result.description) {
            descriptionCode = `<p><span class="hint-warning">${result.description}</span></p>`;
        }

        let confirmationCode = '';
        if (result.confirmation) {
            confirmationCode = (
                '<p><span class="b-indent">' +
                    '<span class="b-indent-icon">' +
                        `<input type="checkbox" class="checkbox" id="${this._confirmationCheckboxId}"/>` +
                    '</span>' +
                    `<label for="${this._confirmationCheckboxId}">${result.confirmation}</label>` +
                '</span></p>'
            );
        }

        document.getElementById(this._contentContainerId).innerHTML = contentCode + descriptionCode + confirmationCode;

        const checkboxes = this._componentElement.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('click', () => {
                let enableYesButton = true;
                this._componentElement.querySelectorAll('input[type="checkbox"]').forEach(confirmationCheckbox => {
                    if (!confirmationCheckbox.checked) {
                        enableYesButton = false;
                    }
                });
                if (enableYesButton) {
                    this._enableYesButton();
                } else {
                    this._disableYesButton();
                }
            });
        });

        if (!checkboxes.length) {
            this._enableYesButton();
        }
    }
}

export class WebspaceFoldersMessageBox extends AjaxMessageBox {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._successDescription = this._getConfigParam('successDescription', '');
        this._failureDescription = this._getConfigParam('failureDescription', '');
    }

    show() {
        if (!this._progress) {
            this._setDescription([]);
        }
        super.show();
    }

    _setDescription(folders) {
        const list = folders.map(({ name }) => `<li>${escapeHtml(name)}</li>`);
        const message = folders.length
            ? this._description.concat(' ').concat(this._successDescription).concat(`<ul>${list.join('')}</ul>`)
            : this._description;
        document.getElementById(this._contentContainerId).innerHTML = message;
    }

    _onSuccess(transport) {
        this._hideSpinner();
        this._enableYesButton();
        try {
            this._setDescription(JSON.parse(transport.responseText).data);
        } catch (e) {
            showInternalError(transport.responseText);
        }
    }

    _onFailure() {
        super._onFailure();
        document.getElementById(this._contentContainerId).innerHTML = `${this._description} ${this._failureDescription}`;
    }
}

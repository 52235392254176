// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import PageLayout from './PageLayout';

const withPageLayout = (Component, extraProps) => {
    const Wrapper = wrapperProps => {
        const {
            baseUrl,
            logo,
            showFrames,
            pageHeader,
            pageSidebar,
            pageContentHeader,
            withSecondary,
            secondary,
            width,
            ...props
        } = {
            ...wrapperProps,
            ...(typeof extraProps === 'function' ? extraProps(wrapperProps) : extraProps),
        };

        return (
            <PageLayout
                baseUrl={baseUrl}
                logo={logo}
                showFrames={showFrames}
                pageHeader={pageHeader}
                pageSidebar={pageSidebar}
                pageContentHeader={pageContentHeader}
                withSecondary={withSecondary}
                secondary={secondary}
                width={width}
            >
                <Component {...props} />
            </PageLayout>
        );
    };
    Wrapper.displayName = `withPageLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default withPageLayout;
